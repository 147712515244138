/*
// jei reikia, galime perrasyti core komponentus, pvz.
init_tooltips = function(context) {
	$('[data-toggle="tooltip"]', context).tooltip({placement:'bottom'});
}
*/

function init_plans_desc() {
    if (get_vw() > screen_md_min) {
        $('.checkout-plans-wrp .bottom-wrp').addClass('desc-active');
        $('.checkout-plans-wrp .plan-element .features-desc').animate({
            height: "show",
            opacity: "show"
        }, "normal");
    } else {
        $('.checkout-plans-wrp .bottom-wrp').removeClass('desc-active');
        $('.checkout-plans-wrp .plan-element .features-desc').animate({
            height: "hide",
            opacity: "hide"
        }, "normal");
    }
}

function init_scroll_content_menu() {
    var lastId,
        topMenu = $(".scrollContentMenu"),
        topMenuHeight = topMenu.outerHeight() + 1,
        // All list items
        menuItems = topMenu.find(".menu-item"),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
            var item = $($(this).data('id'));
            if (item.length) { return item; }
        });

    // Bind click handler to menu items
    // so we can get a fancy scroll animation
    menuItems.on('click', function(e){
        var href = $(this).data('id'),
            offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight - 10;
        $('html, body').stop().animate({
            scrollTop: offsetTop
        }, 850);
        e.preventDefault();
    });

    const scrollIntoViewHorizontally = (container,child) => {
        const child_offsetRight = child.offsetLeft + child.offsetWidth;
        const container_scrollRight = container.scrollLeft + container.offsetWidth;

        if (container.scrollLeft > child.offsetLeft) {
            container.scrollLeft = child.offsetLeft;
            return;
        }

        if (container_scrollRight < child_offsetRight) {
            container.scrollLeft += child_offsetRight - container_scrollRight;
        }
    };

    function update_menu (topMenu, menu_items) {
        // Get container scroll position
        var fromTop = $(this).scrollTop() + topMenuHeight + 20;

        // Get id of current scroll item
        var cur = scrollItems.map(function(){
            if ($(this).offset().top < fromTop)
                return this;
        });
        // Get the id of the current element
        cur = cur[cur.length-1];
        var id = cur && cur.length ? cur[0].id : "";

        if (!cur) {
            topMenu.fadeOut()
        } else {
            topMenu.fadeIn()
        }
        if (lastId !== id) {
            lastId = id;
            // Set/remove active class
            menuItems
                .parent().removeClass("active")
                .end().filter("[data-id='#"+id+"']").parent().addClass("active");

            if (id != "" && get_vw() < screen_md_min) {
                var element = document.querySelector("[data-id='#"+id+"']");

                element.scrollIntoView({
                    behavior: 'instant',
                    block: 'nearest',
                    inline: 'center',
                });
            }
        }
    }

    update_menu(topMenu, menuItems);
    // Bind to scroll
    $(window).scroll(function(){
        update_menu(topMenu, menuItems);
    });
}

$(function () {
    window.scroll_block_diff = 0;
    update_breadcrumb();
    init_selectpicker();
    init_infinite_carousel();
    init_plans_desc();
    header_fixed();
    scroll_block();
    init_scroll_content_menu();

    $(window).resize(function () {
        update_breadcrumb();
        init_infinite_carousel();
        init_plans_desc();
    });

    $(window).scroll(function() {
        header_fixed();
        scroll_block();
    });

    // scroll block tabs
    $('.editor_type_scroll_block .nav-tabs .nav-link').on('click', function() {
        let new_vh = $(this).data('count');
        let parent = $(this).parents('.page_editor_template');
        let parent_id = parent.attr('id');

        $(document).scrollTop(parent.offset().top);
        parent.attr('data-block-vh', new_vh).data('block-vh', new_vh);
    });

    $(document).on('click', '.editor_type_features .btn-more', function (e) {
        e.preventDefault();
        let inner = $(this).closest('.inner-wrp');
        let list = $(this).closest('.inner-wrp').find('.benefits-list');

        list.animate({height: list.get(0).scrollHeight}, 300, function () {
            inner.addClass('show');
        });
    });

    $(document).on('click', '.editor_type_features .btn-less', function (e) {
        e.preventDefault();
        $(this).closest('.inner-wrp').removeClass('show');
        $(this).closest('.inner-wrp').find('.benefits-list').removeAttr('style');
        $('html, body').animate({
            scrollTop: $(this).closest('.editor_type_features').offset().top
        }, 900, 'swing');
    });

    $(document).on('click', '.menu-bars', function (e) {
        e.preventDefault();

        $(this).toggleClass('active');
        $('#site-header').toggleClass('active');
        $('#main-menu').toggleClass('active');
    });

    if (get_vw() > screen_lg_min) {
        $('.main-menu li.has-submenu .main-link').click(function(e) {
            e.preventDefault();
        });

        $('.main-menu li.has-submenu a').removeAttr('data-bs-toggle');

        $('.main-menu li.has-submenu').hover(function () {
            $('#page-backdrop').addClass('active');
        }, function () {
            $('#page-backdrop').removeClass('active');
        });

        $('.main-menu li.has-submenu').mouseenter(function () {
            let target = $(this).find('.main-link').data('bs-target');
            $(target).addClass('active');
            $('body').addClass('menu-open');
        });

        $('.main-menu li.has-submenu').mouseleave(function(){
            let target = $(this).find('.main-link').data('bs-target');
            $(target).removeClass('active');
            $('body').removeClass('menu-open');
        });

        $('.link-parent').on('mouseenter', function() {
            $(this).addClass('link-hovered');
        }).on('mouseleave', function() {
            $(this).removeClass('link-hovered');
        });

        $('.main-menu .submenu-wrp.wt-banners a[data-item-id^="fb-item-"]').on('mouseenter', function() {
            $(this).parents('.wt-banners').find('.menu-banner').removeClass('active');
            $(this).parents('.wt-banners').find('a[data-item-id^="fb-item-"]').removeClass('active');
            $(this).addClass('active');

            $('.menu-banner.'+ $(this).data('item-id')).addClass('active');
        });
    }

    if (get_vw() < screen_md_min) {
        if ($('#account-menu').length) {
            var element = document.querySelector("#account-menu ul li a.active");

            element.scrollIntoView({
                behavior: 'instant',
                block: 'nearest',
                inline: 'center',
            });
        }
    }

    if (get_vw() <= screen_xs) {
        var cardListSum = 16;

        $('.editor_type_cards_list .cards-list .card-item:lt(3)').each(function(index) {
            if(index === 2){
                cardListSum += $(this).outerHeight() / 3;
            }else{
                cardListSum += $(this).outerHeight();
            }
        });

        $('.editor_type_cards_list .cards-list').css('height', cardListSum.toFixed() + 'px');

        $(document).on('click', '.editor_type_cards_list .btn-more', function (e) {
            e.preventDefault();
            let inner = $(this).closest('.editor_type_cards_list');
            let list = $(this).closest('.editor_type_cards_list').find('.cards-list');

            list.animate({height: list.get(0).scrollHeight}, 300, function () {
                inner.addClass('show');
            });
        });
    }

    $(document).on('click', '.footer-collapse-mobile > .title', function(e) {
        if (get_vw() <= screen_xs_max) {
            e.preventDefault()
            $(this).parent().toggleClass('open');
            $(this).parent().find('.dropdown-menu').slideToggle();
        }
    });

    if ($('#clients-page-content').length) {
        $('.nav-tabs li h4[aria-selected="true"]').closest('li').addClass('active');
    }

    $('#clients-select').on('changed.bs.select', function(e) {
        let selected = $(e.currentTarget).val();
        let parent = $(this).parent();

        if (selected == 'all') {
            parent.find('> button.dropdown-toggle').removeClass('filter-selected');
        } else {
            parent.find('> button.dropdown-toggle').addClass('filter-selected');
        }

        $('.nav-tabs li').removeClass('active');
        $('[data-bs-target="#client-tab-' + selected + '-pane"]').closest('li').addClass('active');

        const triggerEl = document.querySelector('[data-bs-target="#client-tab-' + selected + '-pane"]')
        const tabTrigger = new bootstrap.Tab(triggerEl);
        tabTrigger.show();
    });

    $(document).on('keyup', '#contact-form-page #contact-form input', function() {
        $(this).removeClass('is-invalid');
    });

    if (get_vw() < screen_md && $('.prices-cards-list').length && false) { // nereikalingas
        tns({
            container: '.prices-cards-list',
            items: 1,
            gutter: 4,
            loop: false,
            autoWidth: true,

            autoplayButtonOutput: false,
            autoplay: false,
            mouseDrag: true,
            nav: true,
            navPosition: 'bottom',
            controls: false,
            autoplayTimeout: 4000,
            speed: 500,
            responsive: {
                0: {
                    autoHeight: true,
                },
                768: {
                    autoHeight: false,
                },
            }
        });
    }

    $('a').on('click', function (e) {
        if (this.hash !== '' && this.hash != '#contactus-modal') {
            e.preventDefault();
            let hash = this.hash;

            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 800);
        }
    });

    if (window.location.hash) {
        var hash = window.location.hash;

        if ($(hash).length) {
            $('html, body').animate({
                scrollTop: $(hash).offset().top
            }, 900, 'swing');
        }

        if ($('[data-bs-target="'+ hash +'"]').length) {
            $(hash).modal('show');
        }
    }

    $('a[href="#contactus-modal"]').on('click', function(e) {
        e.preventDefault();

        if ($('[data-bs-target="#contactus-modal"]').length) {
            $('#contactus-modal').modal('show');
        }
    });

    $('.checkout-plans-wrp .plan-element button[data-action="toggle-desc"]').on('click', function() {
        $(this).parent().toggleClass('desc-active');
        $(this).parent().find('.features-desc').animate({
            height: "toggle",
            opacity: "toggle"
        }, "normal");
    });
});